export const pageViewEvent = () => {
  window.fbq('track', 'PageView');
};

export const filledStartAProject = () => {
  window.fbq('track', 'CompleteRegistration');
};

export const visitedContactPage = () => {
  window.fbq('track', 'Lead');
};

export const filledContactUs = () => {
  window.fbq('track', 'CompleteRegistration');
};
