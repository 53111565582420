import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  p: { xs: '0 20px', sm: '0 20px' }
};
const Popup = ({ response, setIsApiRes }) => {
  const { isError, openPopup } = response;

  const navigate = useNavigate();
  const handleClose = () => {
    setIsApiRes({
      openPopup: false
    });
    navigate('/');
  };

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        open={openPopup}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        disableEnforceFocus
        disableAutoFocus
      >
        <Box sx={style}>
          <CloseIcon sx={{ position: 'absolute', right: 0, top: '0', color: '#fff', cursor: 'pointer', fontSize: '30px' }} onClick={handleClose} />
          {isError && (
            <Typography variant='h2' sx={{ color: '#fff', fontSize: '65px', pb: '20px' }}>
              ERROR
            </Typography>
          )}
          <Typography variant='h2' sx={{ color: '#fff', fontSize: '55px' }}>
            {isError ? 'Oops! Something went wrong.' : 'Thank you for your interest in our services!'}
          </Typography>
          <Typography variant='h3' sx={{ color: '#fff' }}>
            {isError ? 'Please try again later.' : 'We will review your enquiry and get back to you shortly.'}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
