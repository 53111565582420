import { Backdrop, Box, Button, CircularProgress, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as yup from 'yup';
import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import { useEffect, useState } from 'react';
import FocusError from './FocusError';
import styles from './Contact.module.scss';
import { callIcon, contact, locationIcon, mailIcon } from '../../Images';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config'; // Your Firebase config
import { Interweave } from 'interweave';
import Popup from '../../components/Popup/Popup';
import { filledContactUs, visitedContactPage } from '../../MetaPixelEvents';

const Contact = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [isApiRes, setIsApiRes] = useState({
    openPopup: false,
    isError: false
  });
  const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required'),
    email: yup.string().required('please enter the email id').email(),
    subject: yup.string('Subject please').required('Subject is required'),
    message: yup.string('Message please').required('Message is required')
  });
  useEffect(() => {
    visitedContactPage();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  const addData = async (values) => {
    try {
      const docRef = await addDoc(collection(db, 'contact_us'), {
        ...values
      });
      setIsLoading(false);
      console.log('Document written with ID: ', docRef.id);
      setIsApiRes({
        isError: false,
        openPopup: true
      });
      filledContactUs();
    } catch (e) {
      console.error('Error adding document: ', e);
      setIsApiRes({
        isError: true,
        openPopup: true
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      values['created_date'] = new Date();
      setIsLoading(true);
      await addData(values);
      resetForm();
    }
  });
  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      //   border: '10px solid #000',
      //   backgroundColor: '#fff',
      borderRadius: '0',
      '& fieldset': {
        borderColor: '#000' // Default border color
      },
      '&:hover fieldset': {
        borderColor: '#000' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000' // Border color when focused
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        // borderColor: '#000'
      }
    }
  };
  const TextFieldErrorMsg = ({ name }) => {
    return <ErrorMessage name={name}>{(msg) => <div className={styles['error-message']}>{msg}</div>}</ErrorMessage>;
  };
  const contactDetails = [
    {
      icon: mailIcon,
      value: 'info@mudrost.in',
      type: 'mail'
    },
    {
      icon: callIcon,
      value: '+91 8431014505',
      type: 'call'
    },
    {
      icon: locationIcon,
      value: '17,1st cross road, New Saptagiri Layout,<br /> Subramanyapura, Bengaluru 560061',
      type: 'location'
    }
  ];
  return (
    <>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Popup response={isApiRes} setIsApiRes={setIsApiRes} />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <Grid item sm={12} xs={12}>
          <Typography variant='h2' sx={{ pb: '10px' }}>
            contact
          </Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6}>
          <Box sx={{ marginLeft: { xs: '-30px', sm: '-30px', md: '-40px' }, marginTop: '-15%' }}>
            <img src={contact} alt='' width='100%' />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid container>
            {contactDetails.map(({ icon, value, type }, cIndex) => (
              <Grid item md={12} key={cIndex}>
                <a
                  href={type === 'mail' ? 'mailto:info@mudrost.in' : type === 'call' ? 'tel:+91 8431014505' : 'https://maps.app.goo.gl/SkdYsx8a5bewAR2w7'}
                  style={{ textDecoration: 'none', color: '#000' }}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Box sx={{ display: 'flex', alignItems: cIndex === 2 ? 'flex-start' : 'center', mb: '30px' }}>
                    <img src={icon} alt={value} width={mobile ? '20%' : '7%'} style={{ marginRight: '10px' }} />
                    <Typography variant='body1' sx={{ fontSize: '20px' }}>
                      <Interweave content={value} />
                    </Typography>
                  </Box>
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <FormikProvider value={formik}>
        <FocusError />
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
            <Grid item md={12} xs={12} sm={12} sx={{ textAlign: 'center', p: '20px' }}>
              <Typography variant='h2' sx={{ marginLeft: { md: '20%', xs: '25%', sm: '25%' } }}>
                click & connect
              </Typography>
            </Grid>
            <Grid item md={2} xs={1} sm={1}></Grid>
            <Grid item md={1} xs={2} sm={2} className={styles['cnt-label']}>
              <Typography variant='body1'>name</Typography>
            </Grid>
            <Grid item md={1} xs={1} sm={1}></Grid>
            <Grid item md={7} xs={7} sm={7}>
              <TextField
                className={styles['cnt-text-fld']}
                fullWidth
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={textFieldStyles}
              />
              <TextFieldErrorMsg name='name' />
            </Grid>
            <Grid item md={1} xs={1} sm={1}></Grid>
            <>
              <Grid item md={2} xs={1} sm={1}></Grid>
              <Grid item md={1} xs={2} sm={2} className={styles['cnt-label']}>
                <Typography variant='body1'>email id</Typography>
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
              <Grid item md={7} xs={7} sm={7}>
                <TextField
                  className={styles['cnt-text-fld']}
                  fullWidth
                  id='email'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={textFieldStyles}
                />
                <TextFieldErrorMsg name='email' />
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
            </>
            <>
              <Grid item md={2} xs={1} sm={1}></Grid>
              <Grid item md={1} xs={2} sm={2} className={styles['cnt-label']}>
                <Typography variant='body1'>subject</Typography>
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
              <Grid item md={7} xs={7} sm={7}>
                <TextField
                  className={styles['cnt-text-fld']}
                  fullWidth
                  id='subject'
                  name='subject'
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={textFieldStyles}
                />
                <TextFieldErrorMsg name='subject' />
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
            </>
            <>
              <Grid item md={2} xs={1} sm={1}></Grid>
              <Grid item md={1} xs={2} sm={2} className={styles['cnt-label']}>
                <Typography variant='body1'>message</Typography>
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
              <Grid item md={7} xs={7} sm={7}>
                <TextField
                  className={styles['cnt-text-fld']}
                  fullWidth
                  id='message'
                  name='message'
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={textFieldStyles}
                  multiline
                  rows={2}
                  //   maxRows={4}
                />
                <TextFieldErrorMsg name='message' />
              </Grid>
              <Grid item md={1} xs={0} sm={1}></Grid>
            </>
            <Grid item md={12} sm={12} xs={12} sx={{ textAlign: 'center' }}>
              <Button
                color='primary'
                variant='contained'
                fullWidth
                type='submit'
                className={styles['submit-btn']}
                sx={{ width: { md: '10%', sm: '35%', xs: '35%' }, marginLeft: '20%', fontSize: '20px' }}
              >
                submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
};

export default Contact;
